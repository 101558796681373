import { Controller } from "@hotwired/stimulus"

function preloadImage(image_url) {
    let img = new Image();
    img.src = image_url;
}

function preloadPetData(petData) {
  if(window.innerWidth <= 480) {
    preloadImage(petData.input0_small_datum.file);
    preloadImage(petData.input1_small_datum.file);
    preloadImage(petData.input2_small_datum.file);
    preloadImage(petData.product_small_datum.file);
    preloadImage(petData.art_image_small_datum.file);
    preloadImage(petData.custom_art_small_datum.file);
  } else {
    preloadImage(petData.input0_datum.file);
    preloadImage(petData.input1_datum.file);
    preloadImage(petData.input2_datum.file);
    preloadImage(petData.product_datum.file);
    preloadImage(petData.art_image_datum.file);
    preloadImage(petData.custom_art_datum.file);
  }
}

function setProductAnimationListener(callback) {
  // If the browser supports LCP, use that to determine when the page is loaded.
  // This will prevent PageSpeed Insights from thinking that the animating
  // products are preventing the LCP.
  if(PerformanceObserver.supportedEntryTypes.includes('largest-contentful-paint')) {
    const lcpObserver = new PerformanceObserver(callback);
    lcpObserver.observe({ type: 'largest-contentful-paint', buffered: true });
  } else {
    window.addEventListener("load", callback);
  }
}

// Connects to data-controller="landing"
export default class extends Controller {
  static targets = ['petSelector', 'product', 'artImage', 'input0', 'input1', 'input2', 'petName', 'customArt'];
  static values = {imageData: Object, firstPet: String}

  initialize() {
    this.pet = this.firstPetValue;

    // After everything is loaded, start changing the mug, and preload images
    setProductAnimationListener((_) => {
      // Do the first pet first.
      preloadPetData(this.imageDataValue[this.pet]);
      let pets = Object.keys(this.imageDataValue);
      for (let i = 0; i < pets.length; i++) {
        if(pets[i] != this.pet) {
          preloadPetData(this.imageDataValue[pets[i]]);
        }
      }
    });
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  changeProduct() {
    let productUrl = this.imageDataValue[this.pet].product_datum.file;
    let productSmallUrl = this.imageDataValue[this.pet].product_small_datum.file;
    let productAlt = this.imageDataValue[this.pet].product_datum.alt;
    this.updatePictureTagSources(this.productTarget, productUrl, productSmallUrl, productAlt);
  }

  changeArt() {
    let artUrl = this.imageDataValue[this.pet].art_image_datum.file;
    let artSmallUrl = this.imageDataValue[this.pet].art_image_small_datum.file;
    let artAlt = this.imageDataValue[this.pet].art_image_datum.alt;
    this.updatePictureTagSources(this.artImageTarget, artUrl, artSmallUrl, artAlt);
  }

  updatePictureTagSources(pictureTag, url, smallUrl, alt) {
    pictureTag.childNodes[0].srcset = url;
    pictureTag.childNodes[1].srcset = smallUrl;
    pictureTag.childNodes[2].src = url;
    if (alt) {
      pictureTag.childNodes[2].alt = alt;
    }
  }

  selectPet(event){
    this.petSelectorTargets.forEach((target) => {
      target.classList.remove('!scale-[130%]');
    })
    event.currentTarget.classList.add('!scale-[130%]');
    this.pet = event.params.pet;
    clearTimeout(this.timeout);
    this.petNameTarget.innerText = this.pet;
    this.changeProduct();
    this.changeArt();
    this.updatePictureTagSources(
      this.input0Target,
      this.imageDataValue[this.pet].input0_datum.file,
      this.imageDataValue[this.pet].input0_small_datum.file,
    );
    this.updatePictureTagSources(
      this.input1Target,
      this.imageDataValue[this.pet].input1_datum.file,
      this.imageDataValue[this.pet].input1_small_datum.file,
    );
    this.updatePictureTagSources(
      this.input2Target,
      this.imageDataValue[this.pet].input2_datum.file,
      this.imageDataValue[this.pet].input2_small_datum.file,
    );
    this.updatePictureTagSources(
      this.customArtTarget,
      this.imageDataValue[this.pet].custom_art_datum.file,
      this.imageDataValue[this.pet].custom_art_small_datum.file,
    );
  }
}
